(function() {
  'use strict';

  angular
    .module('countdown', ['ngAnimate', 'ngCookies', 'ngTouch', 'ngSanitize', 'ngMessages', 'ngAria', 'ui.bootstrap', 'toastr']);

})();

(function() {
  'use strict';

  angular
    .module('countdown')
    .controller('MainController', MainController);

  /** @ngInject */
  function MainController($interval) {
    var vm = this;

    vm.config = {
      'workingHoursPerDay': 8,
      'workingDaysPerWeek': 5,
      'workingDays': [1,2,3,4,5],
      'workStartHour': 10,
      'workEndHour': 18
    };

    calculcate();
    $interval(function() {calculcate()}, 6E4);

    function calculcate() {
      vm.currentDate = new Date();
      vm.startDate = new Date(2016, 10, 14, 10);
      vm.totalHours = 200;
      vm.exitDate = new Date(2016, 11, 16, 18);
      vm.workStart = new Date().setHours(vm.config.workStartHour  , 0);
      vm.workEnd = new Date().setHours(vm.config.workEndHour, 0);

      // Still sleeping
      if (vm.currentDate < vm.workStart) {
        vm.remainingHoursToday = 8;
      } else if (vm.currentDate > vm.workEnd) { // Aready drinking beer
        vm.remainingHoursToday =  0;
      } else { // Calculate remaining working hours
        vm.remainingHoursToday =  Math.abs(vm.workEnd - vm.currentDate) / 36e5;
      }

      // catch div by zero
      if (vm.remainingHoursToday == 0) {
        vm.percentageToday = '100';
      } else {
        vm.percentageToday = Math.round(100 - (vm.remainingHoursToday / (8/100)));
      }

      // working but not what we need in this case
      // vm.remainingDays = Math.round(Math.abs(vm.exitDate - vm.currentDate) / 864e5);
      var compareDate = new Date(vm.currentDate);
      vm.remainingWorkingDays = 0;
      while(compareDate < vm.exitDate){
        if (vm.config.workingDays.indexOf(compareDate.getDay()) != -1) {
          vm.remainingWorkingDays += 1;
        }

        var newDate = compareDate.setDate(compareDate.getDate() + 1);
        compareDate = new Date(newDate);
      }

      // we don not count the current day
      vm.remainingWorkingDays -= 1;
      vm.remainingHoursTotal = Math.floor((vm.remainingWorkingDays * 8) + vm.remainingHoursToday);
      vm.remainingHoursTodayFloor = Math.floor(vm.remainingHoursToday);
      vm.remainingMinutes = Math.round((vm.remainingHoursToday - Math.floor(vm.remainingHoursToday)  ) * 60);
    }
  }
})();

(function() {
  'use strict';

  angular
    .module('countdown')

})();

/* global malarkey:false, moment:false */
(function() {
  'use strict';

  angular
    .module('countdown')
    .constant('malarkey', malarkey)
    .constant('moment', moment);

})();

(function() {
  'use strict';

  angular
    .module('countdown')
    .config(config);

  /** @ngInject */
  function config($logProvider, toastrConfig) {
    // Enable log
    $logProvider.debugEnabled(true);

    // Set options third-party lib
    toastrConfig.allowHtml = true;
    toastrConfig.timeOut = 3000;
    toastrConfig.positionClass = 'toast-top-right';
    toastrConfig.preventDuplicates = true;
    toastrConfig.progressBar = true;
  }

})();
